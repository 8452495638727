:root {
  --template-nav-aside-width: 74px !important;
}

body {
  min-height: 768px;
  min-width: 100%;
  font-family: 'Roboto', sans-serif;
}

html,
body,
.app {
  position: relative;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}