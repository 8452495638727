:root {
  --system-list-web__main__padding-left: 0;
  --system-list-web__main__padding-right: 0;
  --system-list-web__body__container__margin-top: 0;
  --system-list-web__body__container__padding-top: 70px;
  --system-list-web__template__container__padding-right: 20px 10px 16px 10px;
}

@media (min-width: 1024px) {
  :root {
    --system-list-web__main__padding-left: 90px;
    --system-list-web__main__padding-right: 55px;
    --system-list-web__body__container__margin-top: -47px;
    --system-list-web__body__container__padding-top: 0;
  }
}

@media (min-width: 1360px) {
  :root {
    --system-list-web__main__padding-left: 200px;
    --system-list-web__main__padding-right: 192px;
  }
}

.tmpl {
  &-head {
    flex-grow: 1;
  }
  &-head__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &-head &-nav {
    height: 42px;
    width: var(--template-nav-aside-width);
    min-width: var(--template-nav-aside-width);
  }
  &-head &-nav .megapolis-nav__toggle {
    width: 42px;
    height: 42px;
  }
  &-head &-nav .megapolis-nav__toggle-button {
    width: 42px;
    height: 42px;
  }
  &-head &-entry {
    flex-grow: 999;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    height: 42px;
  }
  &-head &-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 24px;
    font-weight: bold;
  }
  &-head &-user {
    width: 42px;
    height: 42px;
  }
  &-head &-user .user-auth {
    width: 40px;
    height: 40px;
  }
  &-head &-user .user-auth-show-button-img {
    width: 42px;
    height: 42px;
  }
}

.system-list-web {
  .tmpl {
    padding: 0;
    display: block;

    &__container {
      padding: var(--system-list-web__template__container__padding-right);
      display: block;
      overflow: auto;
      height: 100vh;
      width: 100vw;
    }

    &-body {
      &__container {
        margin-top: var(--system-list-web__body__container__margin-top);
        padding-top: var(--system-list-web__body__container__padding-top);
      }

      &__content {
        overflow: auto;
      }

      &__loading {
        text-align: center;
      }
    }

    &-main {
      padding-left: var(--system-list-web__main__padding-left);
      padding-right: var(--system-list-web__main__padding-right);
    }
  }
}
